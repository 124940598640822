/*=========================================================================================
  File Name: verticalNavMenu.scss
  Description: Styles for v-nav-menu component. Imported in VerticalNavMenu.vue file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

@import "../_variables.scss";

// swipe area/div
.v-nav-menu-swipe-area {
  position: fixed;
  background: transparent;
  height: calc(var(--vh, 1vh) * 100);
  width: 30px;
  z-index: 1;
  top: 0;
  left: 0;
}

.v-nav-menu {
  white-space: nowrap;

  .vs-sidebar--background {
    z-index: 51;
  }

  .vs-sidebar {
    z-index: 51;
    position: fixed;

    .vs-sidebar--items {
      padding: 0;
    }
    &.vs-sidebar-reduce {
      max-width: $reduced-sidebar-width;
      &:hover {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
      }

      &:hover:not(.vs-sidebar-reduceNotRebound):not(.vs-sidebar-reduceNotHoverExpand) {
        .vs-sidebar--items {
        }

        .vs-sidebar-group .group-header i {
          display: block;
        }
      }
      .vs-sidebar-group .group-header i {
        display: none;
      }
    }
  }

  .header-sidebar {
    padding: 20px 19px 16px 23px;
    width: 100%;
    .logo {
      img {
        padding: 4px 0;
      }
    }

    .feather-icon {
      svg {
        color: #7367f0;
      }
    }
  }

  .shadow-bottom {
    position: absolute;
    z-index: 2;
    height: 60px;
    width: 100%;
    pointer-events: none;
    margin-top: -1.3rem;
    filter: blur(5px);
    background: linear-gradient(rgb(255, 255, 255) 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0) 100%);
  }

  .scroll-area-v-nav-menu {
    position: relative;
    margin: auto;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - 69px);

    &:not(.ps) {
      overflow-y: auto;
    }

    > .vs-sidebar-group {
      padding: 0 15px;
    }

    > .vs-sidebar--item {
      padding: 0 15px;
    }
  }

  .navigation-header {
    font-size: 0.9rem;
    display: block;
    margin-bottom: 0.8rem;
    margin-left: 2.2rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #999;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  .feather-icon {
    color: #565656;
    margin-right: 14px;
    .feather {
      width: 20px;
      height: 20px;
    }
  }

  .con-vs-chip {
    min-height: 24px;
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.1);
    .vs-chip--text {
      color: $white;
      font-size: 0.8rem;
    }
  }

  .vs-sidebar--item {
    transition: none;
    overflow: visible !important;
    &:hover {
      a {
        color: inherit;
        > * {
          transform: translateX(5px);
        }
      }
    }

    &.vs-sidebar-item-active {
      border: none !important;
      font-weight: 400;
      z-index: 1;
      position: relative;
    }

    a {
      font-size: 1rem;
      transition: none;
      border-radius: 4px;
      opacity: unset;
      color: $content-color;
      > * {
        transition: transform 0.25s ease;
      }
      padding: 10px 15px;
      span {
        font-size: 15px;
      }
    }

    &.disabled-item {
      a {
        span {
          color: #e2e2e2;
        }

        .theme-dark & {
          opacity: 0.3;
        }
      }
    }

    .router-link-active {
      background: linear-gradient(118deg, rgba(var(--vs-primary), 1), rgba(var(--vs-primary), 0.7));
      box-shadow: 0px 0px 10px 1px rgba(var(--vs-primary), 0.7);
      // font-weight: 400;
      .feather-icon {
        color: $white;
      }
      span {
        color: $white;
      }
    }
  }
}

#sidebar-demo {
  .vs-sidebar {
    z-index: 52;
  }
  .vs-sidebar-staticPosition {
    z-index: 10;
  }

  #parentx-demo-7 {
    .parentx:not(.show-custom-sidebar) {
      .vs-sidebar {
        display: none;
        z-index: 1 !important;
      }
    }
  }
}
