@import '../_variables.scss';

.menu-item {
  > .h-nav-group-open {
    .group-header {
      background-color: #f6f6f6;

      .theme-dark & {
        background-color: $theme-light-dark-bg;
      }
    }
  }
}

.header-children {
  .h-nav-group-active {
    background-color: #f6f6f6;

    .theme-dark & {
      background-color: $theme-dark-bg;
    }
  }
}

.h-nav-group-items {
  border-radius: 4px;
  min-width: 215px;
  z-index: 1;

  &.dd-left {
    right: 0rem;
  }

  &::-webkit-scrollbar {
   width: 5px;
   height: 5px;
  }

  &::-webkit-scrollbar-thumb {
   background: $grey-light;
   border-radius: 20px;

   .theme-dark & {
    background: $theme-dark-bg;
   }
  }

  &::-webkit-scrollbar-track {
   // background: $theme-background;
   background: $theme-background;
   border-radius: 30px;

   .theme-dark & {
    background: $theme-light-dark-bg;
   }
  }
}

.nav-header {
  .group-header {
    transition: all .2s ease;
    padding: .75rem 1.5rem;
    &:hover {
      transform: translateX(5px)
    }
  }
}

.menu-item {
  > .h-nav-group {
    .group-header {
      border-radius: 4px;
      padding: .5rem 1.5rem;
    }
  }

  > .h-nav-group-active {
    .group-header {
      background: linear-gradient(118deg, rgba(var(--vs-primary),1), rgba(var(--vs-primary),.7)) !important;
      box-shadow: 0px 0px 6px 1px rgba(var(--vs-primary), 0.6);
      color: $white;
    }
  }
}
